.home-container {
    width: 100%;
    /* min-height: calc(100vh - 100px); */

    display: flex;
    /* justify-content: center; */
    margin-top: 20px;
    align-items: center;
    flex-direction: column;

    color: #FFFFFF;

    overflow: auto;
}
.home-cards .ant-card-body{
    padding: 18px !important;
}

  /* #chartdiv div div div .am5-layer-30 { */
    /* display: none !important; */
  /* } */
.home-title {
    text-transform: uppercase;
}

p {
    margin: 15px 0px;
}

.home-overlay {
    background-image: url(https://i.ibb.co/tLWKtvC/ddx.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .25;
    mix-blend-mode: overlay;
    transition: background .3s,border-radius .3s,opacity .3s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -5;
}

.home-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg,#161616 0%,#e90c36 100%);

    height: 700px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -10;
}

/* @media (max-width: 1500px) {
    .home-background-overlay {
        height: 100vh;
    }
} */

.dropdown-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 30px;
  }
  
  .entity-ddl {
    width: 80%;
    max-width: 600px;
    background-color: #055E9A;
    border-radius: 10px;
    color: #FFFFFF;
  }
  
  .entity-ddl .ant-select-selector {
    background-color: #055E9A !important;
    color: #FFFFFF !important;
    border: none !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
  }
  
  .entity-ddl .ant-select-selection-item {
    font-size: 20px;
    font-weight: 400;
  }
  
  .entity-ddl .ant-select-arrow {
    background-color: #FFD700;
    color: black !important;
    border: 1px solid #AFA8A8;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    margin-top: -25px;
    margin-right: -8px;
  }
  
  .custom-dropdown {
    border-radius: 10px;
    background-color: #FFFFFF;
    color: black;
  }
  
  .entity-ddl .ant-select-focused .ant-select-selector,
  .entity-ddl .ant-select-selection-item {
    border: none !important;
    background-color: #055E9A !important;
    color: #FFFFFF !important;
  }

  .custom-dropdown .ant-select-item .ant-select-item-option-content{
    font-size: 20px;
    font-weight: 400;
  }

  .entity-card {
    border-color: #464242;
    margin: 50px;
    margin-top: 30px;
    padding: 0px;
  }
  
  .vertical-divider {
    height: 100%;
    width: 1%;
    background-color: black;
  }
  
  .entity-name {
    font-size: 24px;
    color: #055e9a;
    font-weight: 500;
  }
  
  .company-card {
    margin: 10px 0px;
    box-shadow: none;
    cursor: pointer;
  }
  
  .card-content {
    display: flex;
    justify-content: space-between;
  }
  
  .card-title {
    font-size: 20px;
    font-weight: 500;
  }
  
  .count-box {
    background-color: #055e9a;
    border-radius: 10px;
    width: auto;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
    padding: 30px;
    max-width: 70px;
  }
  
  .count-box span {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
  }
  

/* Button Container */
.button-container {
  display: flex;
  justify-content: center;  /* Horizontally center */
  align-items: center;      /* Vertically center */
  width: 100%;              /* Full width */
  margin-top: 20px;         /* Optional margin */
}

/* Button styling */
button {
  padding: 10px 20px;
  background-color: #055E9A;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #033d66;
}

/* Responsive Styles */
@media (max-width: 1200px) {
.entity-name {
    font-size: 22px;
}

.card-title {
    font-size: 18px;
}

.count-box {
    width: 80px;
    height: 50px;
}

.count-box span {
    font-size: 18px;
}
}

@media (max-width: 992px) {
.entity-card {
    margin: 40px;
}

.dropdown-container {
    margin-top: 20px;
}

.entity-ddl {
    width: 90%;
}

.count-box {
    width: 70px;
    height: 45px;
}

.count-box span {
    font-size: 16px;
}
}

@media (max-width: 768px) {
.entity-card {
    margin: 30px;
}

.company-card {
    margin: 8px 0px;
}

.count-box {
    width: 60px;
    height: 40px;
}

.count-box span {
    font-size: 14px;
}
}

@media (max-width: 576px) {
.entity-card {
    margin: 20px;
}

.entity-name {
    font-size: 20px;
}

.card-title {
    font-size: 16px;
}

.count-box {
    width: 50px;
    height: 35px;
}

.count-box span {
    font-size: 12px;
}
}
