/* Provide sufficient contrast against white background */

@tailwind base;
@tailwind components;
@tailwind utilities;

.css-dev-only-do-not-override-18iikk {
  min-height: unset !important;
}

body {
  font-family: Poppins;  
  font-weight: 300;
  padding: 0;
  margin: 0;
}
.language-div{
  font-size: 20px;
  font-weight: 500;
}

a:hover{
  color: #FEE100;
}


@media (min-width: 1280px) {
  .container{
    max-width: 1370px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
  .navbar-header-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-toggler{
    width: 50%;
  }
}
