.footer-description-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFFE5;
  }
  
  .footer {
    background-color: #055E9A;
  }
  
  .footer-container {
    max-width: 1400px;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .number-link{
    font-size: 12px;
    color: #fff;
  }
  .number-link-bottom{
    font-size: 12px;
    color: #fff;
    margin-left: 35px;
  }
  .nav-icon {
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFFE5;
  }
  
  .nav-icon .mail-icon {
    background-color: #FFF;
    font-weight: 300;
    padding: 10px;
    font-size: 50px;
    border-radius: 30px;
    margin-right: 20px;
    width: 54px;
  }
  
  .footer-divider {
    margin-top: -215px;
    width: 55%;
    min-width: 50%;
    color: #fee10061;
    background-color: #fee10047;
  }
  
  .quick-links {
    font-size: 26px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FEE100;
  }
  
  .mail-div {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .options-div li {
    margin-bottom: 20px;    
  }
  
  
  .options {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFFE5;
  }
  
  .options-div {
    margin-top: 45px;
    width: 60%;
  }
  
  .right-arow {
    margin-top: 5px;
  }
  
  .quick-links-div {
    margin-left: 100px;
  }
  
  .contact-links {
    font-size: 26px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FEE100;
  }
  
  .contact-div {
    margin-top: 45px;
  }
  .options-div a,.contact-div a,.mail-div a, .copyright-text a{
    text-decoration: none;
  }
  
  .snapchat-icon {
    border-color: black;
  }
  
  .contact-card {
    background-color: #CCD1D833;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 12px;
  }
  
  .contact-card .title {
    font-size: 16px;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFFE5;
  }
  
  .instagram-icon {
    background-color: #fff;
    fill: url(#instagram-gradient);
  }
  
  .instagram-icon svg {
    fill: url(#instagram-gradient);
  }
  
  .contact-icons {
    background-color: #fff;
    padding: 2px;
    font-size: 30px;
    border-radius: 5px;
  }
  
  .copyright-text {
    background-color: white;
    text-align: center;
    padding: 8px 0;
    font-size: 14px;
    height: 40px;
  }
  
  .copyright-text p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: black;
    opacity: 0.9;
  }
  
  .footer-kgh {
    color: #055E9A;
  }

  .mail-div {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .mail-div .flex {
    gap: 20px; /* Add spacing between items */
  }
  
  
  @media (max-width: 768px) {
    .footer-container {
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }

    .mail-div {
        flex-direction: column; /* Stack vertically on smaller screens */
      }
      
      .nav-icon {
        margin-bottom: 10px; /* Space between links on small screens */
      }
    
    .mail-div {
      display: block;
      margin-top: 230px;
      padding-left: 2%;
    }
    
    .quick-links-div {
      margin-left: 10px;
      margin-top: 50px;
    }
    
    .contact-links-div {
      margin-top: 50px;
      margin-left: 10px;
    }
    
    .nav-div {
      padding-left: 10px;
      margin-bottom: 10px;
    }
    
    .nav-item div {
      margin-bottom: 10px;
    }
    
    .options-div {
      width: 100%; /* Full width on smaller screens */
    }
    
    .right-arow {
      margin-top: 5px;
      margin-left: auto; /* Align right arrow */
    }
    
    .contact-card {
      padding: 10px 15px; /* Smaller padding for contact cards */
    }
    
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .footer-divider {
        margin-top: 100px; /* Adjust the margin as needed */
        width: 70%; /* Adjust the width as needed */
        min-width: 60%; /* Adjust the min-width as needed */
    }
}

