.confirmation-container {
    background-color: white;
    min-height: 80vh;
    width: 70%;
    margin: auto auto;

    border-radius: 15px;

    padding: 20px;
}

.title-confirm {
    text-align: center;
    font-size: 26px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.success-alert {
    background-color: #D4F2D3;
    color: #007E42;
    width: 100%;
    padding: 20px;
    text-align: center;

    font-size: 22px;

    border-radius: 8px;
}

table {
    margin-top: 70px;
    border-collapse: collapse;
  }

  th, td {
    padding: 0 20px;
    text-align: left;
    border: none;
  }

  th {
    font-weight: bold;
  }


  @media (max-width: 600px) {
    .confirmation-container { 
        width: 100%;
    }
    th, td {
      display: table-cell;
      width: 100%;
      box-sizing: border-box;

     /* margin-bottom: 10px;  */
    }
    td {
        margin-bottom: 10px;
    }
  }


  @media print {
    body * {
      visibility: hidden;
    }
  
    #printable-section, #printable-section * {
      visibility: visible;
    }
  
    #printable-section {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .confirmation-container th:first-child {
    width: 50%;
    font-weight: bold;
}

.confirmation-container th:nth-child(2) {
    width: 50%;
    font-weight: normal;
}