.home-container {
    width: 100%;
    margin-top: -90px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    position: relative; /* Ensures content stays on top of overlays */
    z-index: 10; /* This places the content above the image overlay */
    overflow: auto;
}
.home-cards .ant-card-body{
    padding: 18px !important;
}

  #chartdiv div div div .am5-layer-30 {
    /* display: none !important; */
  }
.home-title {
    text-transform: uppercase;
}
.not-found{
    text-align: center;
    color: #055E9A;
    font-size: 22px;
    padding: 50px 0px;
}
p {
    margin: 15px 0px;
}
.request-overlay {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background .3s,border-radius .3s,opacity .3s;
    margin: 0 auto;
    width: 100%;
    height: 250px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: white;
  }
  .overlay-1 {
    background: linear-gradient(
          rgba(91, 91, 91, 0.5),
          rgba(91, 91, 91, 0.5)
      ),
      url("../../assets/Frame\ 1.jpg");
      
      background-blend-mode: overlay; 
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
  
  }
/* .home-overlay {
    background-image: url("../../assets/Frame\ 1.jpg");;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .25;
    mix-blend-mode: overlay;
    transition: background .3s,border-radius .3s,opacity .3s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -5;
} */

.home-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg,#161616 0%,#e90c36 100%);

    height: 700px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
}
.title-text{
font-size: 28px;
font-weight: 500;
line-height: 60px;
letter-spacing: 0.02em;
text-align: left !important;
color:#FFFFFF;
width: 1400px;
margin-left: 40px;
height: 120px;
}
/* @media (max-width: 1500px) {
    .home-background-overlay {
        height: 100vh;
    }
} */

/* Responsive Styles */

/* Tablets and smaller laptops */
@media (max-width: 992px) {
    .home-container {
        margin-top: -60px !important; /* Adjust for smaller devices */
    }

    .title-text {
        font-size: 24px !important;
        line-height: 50px !important;
        width: 100% !important; /* Ensure it takes full width on smaller screens */
        margin-left: 20px !important;
    }

    .home-background-overlay {
        height: 600px !important; /* Adjust background overlay height */
    }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
    .home-container {
        margin-top: -40px !important; /* Further reduce margin for smaller screens */
    }

    .title-text {
        font-size: 22px !important;
        line-height: 40px !important;
        margin-left: 15px !important;
    }

    .home-background-overlay {
        height: 500px !important;
    }
}

/* Small mobile (max-width: 576px) */
@media (max-width: 576px) {
    .home-container {
        margin-top: -30px !important;
    }

    .title-text {
        font-size: 20px !important;
        line-height: 30px !important;
        margin-left: 10px !important;
        text-align: center !important; /* Center align text for small mobile screens */
        width: 100% !important;
    }

    .home-background-overlay {
        height: 400px !important;
    }
    .search-container .serach-btn {
        border-radius: 10px;
        background-color: rgb(254, 225, 0);
        width: 100% !important;
        margin-left: 5px;
        margin: 0 !important;
    }
}

@media (min-width:1300px) {
    .company-list-container{
      max-width: 1250px !important;
    }
  }
