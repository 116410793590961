.company-list-container {
  max-width: 1380px;
  margin: 0 auto;
  width: 100%;
  font-family: Arial, sans-serif;
}

.company-list-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #055E9A;
  margin: 50px 0px;
  color: white;
  font-weight: bold;
  border-radius: 12px;
  padding: 20px;

}



.company-list-header .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-left: 10px;
  color: #FFFFFFE5;
}

.details-button {
  width: 113px !important;
  height: 44px !important;
  border-radius: 10px !important;
  background-color: #055E9A !important;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.details-button:hover {
  opacity: 0.8;
}

.details-button:focus {
  outline: none;
}

.company-list-item {
  display: flex;
  margin: 30px 0px;
  justify-content: space-between;
  background-color: #FFFFFF;
  color: #000000E5;
  font-weight: bold;
  padding: 15px 20px !important;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 2px solid #CDD3D866
}

.company-list-item-content {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;

}

.company-list-item-content .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
  color: #000000E5;
}

.ant-list-item-action {
  margin-left: 20px;
}

.ant-btn-primary {
  background-color: #0066b2;
  border-color: #0066b2;
}

.results-count {
  text-align: right;
  padding: 10px 20px;
  font-style: italic;
}

 @media (max-width: 768px) {
  .show-divider {
    display: none;
  }
}