.request-overlay {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background .3s,border-radius .3s,opacity .3s;
    margin: 0 auto;
    width: 100%;
    height: 250px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: white;
  }
  .overlay-1 {
    background: linear-gradient(
          rgba(91, 91, 91, 0.5),
          rgba(91, 91, 91, 0.5)
      ),
      url("../../assets/Frame\ 1.jpg");
      
      background-blend-mode: overlay; 
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
  
  }
/* .home-overlay {
    background-image: url("../../assets/Frame\ 1.jpg");;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .25;
    mix-blend-mode: overlay;
    transition: background .3s,border-radius .3s,opacity .3s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -5;
} */

.home-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg,#161616 0%,#e90c36 100%);

    height: 700px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
}
.title-text{
font-size: 28px;
font-weight: 500;
line-height: 60px;
letter-spacing: 0.02em;
text-align: left !important;
color:#FFFFFF;
width: 1400px;
margin-left: 40px;
height: 120px;
}
