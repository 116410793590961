.request-overlay {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background .3s,border-radius .3s,opacity .3s;
    margin: 0 auto;
    width: 100%;
    height: 250px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: white;
  }
  .overlay-1 {
    background: linear-gradient(
          rgba(91, 91, 91, 0.5),
          rgba(91, 91, 91, 0.5)
      ),
      url("../../assets/Frame\ 1.jpg");
      
      background-blend-mode: overlay; 
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
  
  }
/* .home-overlay {
    background-image: url("../../assets/Frame\ 1.jpg");;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .25;
    mix-blend-mode: overlay;
    transition: background .3s,border-radius .3s,opacity .3s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -5;
} */

.home-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg,#161616 0%,#e90c36 100%);

    height: 700px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
}
.ant-table-wrapper{
    margin: 0px 20px;
}
.filter-div{
    display: flex;
    justify-content: space-between;
}
.filter-div button{
    margin:0px 20px;
    margin-bottom: 0px;
    background-color: #055e9a;
    color: white;
    font-size: 16px;
    padding: 10px !important;
    height: 50px !important;
}

.filter-div button:hover{
    color: white;
    opacity: 0.9;
}
.title-text{
font-size: 28px;
font-weight: 500;
line-height: 60px;
letter-spacing: 0.02em;
text-align: left !important;
color:#FFFFFF;
width: 1400px;
margin-left: 40px;
height: 120px;
}

.select-year-ddl{
    width: 200px !important;
    border-radius: 20px !important;
    margin-left: 20px;
    margin-top: 0px;
}

.select-year-ddl .ant-select-selector{
    height: 55px !important;
    font-size: 18px !important;
    border-radius: 20px !important;
}

.select-year-ddl .ant-select-selector{
    padding: 10px !important;
    font-size: 18px !important;
}


.select-year-ddl .ant-select-selector .ant-select-selection-placeholder{
    font-size: 18px !important;
}

/* .select-year-ddl .ant-select-selector .ant-select-selection-item{
    padding: 10px !important;
} */


.ant-table-tbody{
    border-radius: 5px !important;
  }
  .ant-table-cell{
   border: 3px solid #CDD3D866 !important;
    border-radius: 5px;
  }
  
  .ant-table-placeholder  .ant-table-cell{
    border-radius: 5px !important;
  }

  .details-overlay {
    background-position: center;
    background-size: cover;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.title-text {
    font-size: 28px;
    text-align: left;
    color: white;
    margin: 20px;
}

/* Filter Section Styling */
.filter-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.select-year-ddl {
    width: 200px;
    font-size: 16px;
}

.filter-div button {
    background-color: #055e9a;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
}

/* Table Styling */
.table-container {
    max-width: 1800px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    /* overflow: auto; */
}

.table-responsive {
    overflow-x: auto;
    width: 100%;
}

@media (max-width: 1024px) {
    .filter-div {
        flex-direction: column;
        align-items: flex-start;
    }

    .select-year-ddl {
        width: 100%;
        margin-bottom: 20px;
    }

    .filter-div button {
        width: 100%;
    }

    .title-text {
        font-size: 24px;
    }

    .table-container {
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    .details-overlay {
        height: 200px;
    }

    .title-text {
        font-size: 20px;
        text-align: center;
    }

    .filter-div {
        flex-direction: column;
        align-items: center;
    }

    .select-year-ddl {
        width: 100%;
        margin-bottom: 15px;
        /* width: 200px !important; */
        border-radius: 20px !important;
        margin-left: 0;
        margin-top: 0;
    }

    .filter-div button {
        width: 50%;
        margin:20px 100px;
    }

    .table-container {
        overflow-x: auto;
    }
}

@media (max-width: 480px) {
    .title-text {
        font-size: 18px;
    }

    .filter-div {
        align-items: center;
        text-align: center;
    }

    .select-year-ddl {
        font-size: 14px;
    }

    .filter-div button {
        font-size: 14px;
        padding: 8px 15px;
    }
}


  