a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  text-decoration: none;
}
.nav-div{
  width: 70%;
  display: flex;
  justify-content: start;
  padding-left: 80px;
}
.nav-list .nav-item .nav-link{
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 300;
  line-height: 33px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 5px 0;
  margin: 10px 20px;
  
}
.header-transparent{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.4s;
  z-index: 999;
}
.nav-list .nav-item .nav-link:hover{
  font-size: 20px;
  font-weight: 500;
  padding: 5px 0;
  margin: 10px 20px;
  text-align: left;
  color: #FEE100;
  text-decoration: none;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures it stays on top of other content */
}
.nav-list .nav-item  .active{
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  padding: 5px 0;
  margin: 10px 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #FEE100;
  border-bottom: 1px solid #FEE100;
}

html {
  font-size: 20px;
}
@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
  .navbar-header-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-toggler{
    width: 50%;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}



.sticky-header {
  background-color: #055E9A;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-expand-md {
  width: 100%;
  max-width: 1400px;
}

.navbar-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.navbar-brand {
  color: white;
  font-size: 46px;
}

.navbar-toggler {
  border: none;
  background-color: white;
}

.nav-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-item .nav-link {
  color: white;
  font-size: 18px;
  padding: 10px;
}

.nav-item .nav-link.active {
  font-weight: bold;
  /* text-decoration: underline; */
}



@media (max-width: 1024px) {
  .navbar-brand {
    font-size: 36px;
  }

  .nav-item .nav-link {
    font-size: 16px;
  }

  .nav-div {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-list {
    flex-direction: column;
    width: 100%;
  }

  .navbar-collapse {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-toggler {
    width: 50% !important;
  }
  /* .navbar-header-container{
    width: 110px !important;
  } */
}

@media (max-width: 768px) {
  .navbar-brand {
    font-size: 30px;
  }

  .nav-div {
    align-items: center;
  }

  .navbar-collapse {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-item .nav-link {
    font-size: 14px;
    padding: 8px;
  }
  .navbar-toggler {
    width: 10% !important;
}
  .navbar-toggler:hover, .navbar-toggler:focus{
    background-color: transparent !important;
    box-shadow: 0 0 0 0;
  }
}

@media (max-width: 480px) {
  .navbar-brand {
    font-size: 24px;
  }

  .nav-div {
    align-items: center;
  }

  .navbar-collapse {
    align-items: center;
  }

  .nav-item .nav-link {
    font-size: 12px;
    padding: 6px;
  }
}
