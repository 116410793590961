.search-container {
  position: relative;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  background-color: #055E9A;
  max-width: 1380px; /* Changed to max-width for better responsiveness */
  height: 125px;
  margin-top: 20px; /* Removed the large negative margin */
  border-radius: 20px;
}

.search-container h1, .search-container p {
  z-index: 10;
}

.search-container-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0;
  border-radius: 15px;
  width: 100%;
  max-width: 1200px; /* Set a max width for better control */
  /* margin: 0 auto; */
  position: relative;
}

button {
  width: 150px;
  height: 60px !important;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.input {
  /* margin-right: 15px;
  width: 100%;
  height: 40px; */
  width: 600px;
height: 65px;
padding-left: 25px !important;
/* top: 330px;
left: 173px; */
gap: 0px;
border-radius: 12px;
border: 2px 0px 0px 0px;
opacity: 1 !important;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;


}
.input1 .ant-select-selector .ant-select-selection-item{
  gap: 0px;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  opacity: 1 !important;
  padding: 10px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  background-color: #fff;
  color: #999999;
}


.input1 .ant-select-selector{
  width: 600px !important;
height: 65px !important;

gap: 0px;
border-radius: 12px;
border: 2px 0px 0px 0px;
opacity: 1 !important;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;

}
.input1 .ant-select-focused  .ant-select-selector{
  width: 600px !important;
height: 65px !important;

gap: 0px;
border-radius: 12px;
border: 2px 0px 0px 0px;
opacity: 1 !important;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;

}
.input1 .ant-select-focused  .ant-select-selector {
  width: 600px !important;
  height: 65px !important;
  padding: 0 !important;
  padding-left: 10 !important;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}
.input1  .ant-select-selector .ant-select-selection-placeholder{
  gap: 0px;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  opacity: 1 !important;
  padding-left: 10 !important;
  padding: 10px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  background-color: #fff;
  color: #999999;
}


.input1 .ant-select-selector .ant-select-selection-item{
  gap: 0px;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  opacity: 1 !important;
  padding: 10px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  background-color: #fff;
  color: #999999;
}




.input1 .ant-select-selector .ant-select-selection-placeholder{
  gap: 0px;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  opacity: 1 !important;
  padding: 10px !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}
.input1 .ant-select-arrow .ant-select-suffix svg{
  width: 30px !important;
  height: 20px !important;
}
@media (min-width : 1300px) {
  .search-container{
    max-width: 1200px !important;
  }
  .input{
    width: 500px;
  }
  .input1 .ant-select-selector,
  .input1 .ant-select-selector .ant-select-selection-item,
  .input1 .ant-select-focused  .ant-select-selector{
    max-width:500px !important;
  }
}
@media (max-width: 1300px) {
  .search-container {
    padding-left: 10px;
    padding-right: 10px;
    
  }

  .search-container-form {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: stretch; /* Stretch items to full width on mobile */
  }

  .input {
    margin-right: 0; /* Remove margin on mobile */
    margin-bottom: 15px;
  }

  button {
    width: 100%; /* Full width on mobile */
  }
}
@media (max-width : 575px)  {
  .input1 .ant-select-selector
  .input1 .ant-select-selector .ant-select-selection-item
  .input1 .ant-select-focused  .ant-select-selector{
    max-width:600px !important;
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .serach-btn{
    margin-left: 50% !important;
    display: flex;
  }
  .serach-btn span{
margin-top: 10px;
  }
  .home-container{
    padding: 15px !important;
  }
  .search-container{
    height: 300px;
  }

  .input{
    max-width:600px;
    width: 100%;
  }
  .input1{
    max-width:600px;
    width: 100%;
  }
  .input1 .ant-select-selector,
  .input1 .ant-select-selector .ant-select-selection-item,
  .input1 .ant-select-focused  .ant-select-selector{
    max-width:600px !important;
    width: 100% !important;
  }
}
