/* .register-container {
    width: 100%;
       max-width: 1380px;
       
} */

.section-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border-bottom: solid 1px #ddd; */
    margin-top:0px !important;
    padding-bottom: 20px;
}
.section-title{
font-size: 26px;
font-weight: 500;
line-height: 39px;
text-align: left;
color: #055E9A;
}
.ant-collapse-header-text{
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #055E9A;
    margin-top: -5px;
}
.compnay-title{
font-size: 28px;
font-weight: 500;
line-height: 42px;
text-align: left;
display: flex;
justify-content: start;
padding-left: 100px;
    margin-top: 50px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #055E9A;
}
.section-div{
    width: 100%;
    padding: 20px 35px;
}
.ant-collapse-expand-icon .anticon svg{
    width: 20px;
    height: 20px;
    color: #055E9A;
}

.section-deatils-title{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.section-deatils-title span{
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #999999;
}

.section-deatils-title b{
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-align: right;
    color: #000000E5;
}
.details-overlay .title-text {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    margin-top: 30px;
    width: 1400px;
    margin-left: 40px;
}
.section-card{
    width: 100%;
    max-width: 1400px;
gap: 0px;
border-radius: 20px;
border: 3px solid #CDD3D866;
background-color: #FFFFFF;
}
.details-overlay {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background .3s, border-radius .3s, opacity .3s;
    margin: 0 auto;
    width: 100%;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
}

.details-overlay .overlay-1 {
    background: linear-gradient(rgba(91, 91, 91, 0.5),
            rgba(91, 91, 91, 0.5)),
        url("../../../assets/Frame\ 1.jpg");

    background-blend-mode: overlay;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

}

.company-details-page {
    background-color: #f5f5f5;
    padding: 30px;
}

.header-image {
    background: url('../../../assets/Frame\ 1.jpg') no-repeat center center;
    background-size: cover;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-title {
    color: white;
    font-size: 32px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.details-card {
    background-color: #ffffff;
    padding: 24px;
 
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* .section-title {
    color: #055E9A;
    font-size: 18px;
    margin-bottom: 16px;
} */

.ant-typography {
    font-size: 14px;
    color: #333;
}

a {
    color: #055E9A;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.request-menu a{
    text-decoration: none !important;
}
@media (max-width: 768px) {
    .section-deatils-title {
        display: block;
        justify-content: flex-start; /* Align left */
    }

    .section-deatils-title span {
        display: block;
        margin-bottom: 5px; /* Space between items */
    }

    .section-deatils-title b {
        text-align: left; /* Align left */
        margin-top: 5px; /* Adjust margin */
    }

    .company-title {
        padding-left: 20px; /* Reduce padding */
        font-size: 24px; /* Adjust font size */
        margin-top: 30px; /* Adjust margin */
    }

    .details-overlay .title-text {
        width: auto; /* Remove fixed width */
        margin-left: 0; /* Remove left margin */
    }

    .header-image {
        height: 150px; /* Adjust header image height */
    }

    .section-card {
        padding: 15px; /* Reduce padding */
    }

    .section-title {
        font-size: 22px; /* Adjust section title font size */
    }

    .ant-collapse-header-text {
        font-size: 18px; /* Adjust font size for collapse headers */
    }
}

/* Additional Media Queries for smaller devices */
@media (max-width: 480px) {
    .section-title {
        font-size: 20px; /* Further reduce section title size */
    }

    .section-deatils-title span,
    .section-deatils-title b {
        font-size: 18px; /* Adjust details title font size */
    }

    .company-title {
        font-size: 22px; /* Further reduce company title size */
    }

    .header-image {
        height: 120px; /* Further reduce header image height */
    }

    .details-card {
        padding: 20px; /* Adjust card padding */
    }
}