.request-container {
    background-color: #f4f4f4;
    width: 100%;
    min-height: calc(100vh - 56px);
    /* border-radius: 10px; */
    margin: 0;
    padding: 40px;
    padding-top: 5px;
}

.request-overlay {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background .3s,border-radius .3s,opacity .3s;
  margin: 0 auto;
  width: 100%;
  height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
}

.overlay-1 {
  background: linear-gradient(
        rgba(91, 91, 91, 0.5),
        rgba(91, 91, 91, 0.5)
    ),
    url("../../assets/Frame\ 1.jpg");
    background-blend-mode: overlay; 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

}
.request-container-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border-bottom: solid 1px #ddd; */
  margin-top:0px !important;
  padding-bottom: 20px;
}
.request-container{
  width: 100%;
    max-width: 1400px;
gap: 0px;
border-radius: 20px;
border: 3px solid #CDD3D866;
background-color: #FFFFFF;
}
.request-title{
  font-size: 26px;
font-weight: 500;
line-height: 39px;
text-align: left;
color: #055E9A;
}
#request_certificationNumbers{
  border-radius: 15px;
  padding: 6px;
  margin-top: 1px;
}

#request_certificationNumbers div > span {
  font-size: 16px;
margin-top: -5px;
}
.requirement-text .ant-checkbox-input{
  width: 20px !important;
  height: 20px !important;
}
.requirement-text .ant-checkbox .ant-checkbox-inner{
  width: 20px !important;
  height: 20px !important;
}
.requirement-text .ant-checkbox+span{
  font-size: 18px !important;
   color: #999999;
   font-weight: 500;

}
.input-field .ant-upload-list {
  display: grid;
  grid-template-columns: auto auto auto;

}
.input-field .ant-upload-list .ant-upload-list-item-container{
  width: 355px;
  margin: 10px;
}
.input-field .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action{
  opacity: 10;

}
.ant-upload-wrapper .ant-upload-list::before{
  content: none !important;
  display: none !important;
}
.input-field .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon{
  color: #CBD2D8E5!important;
}
/* .input-field .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions:hover .anticon{
  color: #FFFFFFE5 !important;
} */
.input-field .ant-upload-icon .anticon svg{
  color: #000000E5 !important;
}
.input-field .ant-upload-list-item-container .ant-upload-list-item {
  background-color: #CBD2D8E5;
  color: #000000E5;
  padding: 30px;
  border-radius: 15px;
}
.input-field .ant-upload-list-item-container .ant-upload-icon .anticon svg:hover{
  color: #FFFFFFE5 !important;
}
.input-field .ant-upload-list-item-container .ant-upload-list-item:hover {
  background-color: #055E9A;
  color: #FFFFFFE5 !important;
  
}
.input-field .ant-select-selector{
  /* padding: 23px !important; */
  border-radius: 15px !important;
  height: 50px !important;

}
.ant-select-selection-item{
  color: #999999;
}
textarea{
  border-radius: 15px !important;
}
.input-field .ant-form-item-label label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #999999;

}
.input-field{
  padding-right: 20px;
}
.request-div{
  width: 100%;
  margin-top: 40px;
}
.overlay-2 {
  background: linear-gradient(
    rgba(91, 91, 91, 0.5),
    rgba(91, 91, 91, 0.5)
),
url("../../wallpaper1.jpeg");
background-blend-mode: overlay; 
background-position: center center;
background-repeat: no-repeat;
background-size: cover;
text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}
.title-text{
  font-size: 28px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.02em;
  text-align: center !important;
  color:#FFFFFF;
  width: 1400px;
  margin-top: 50px;
  margin-left: 40px;
  height: 120px;
  }
.overlay-3 {
  background: linear-gradient(
    rgba(91, 91, 91, 0.5),
    rgba(91, 91, 91, 0.5)
),
url("../../wallpaper1.jpeg");
background-blend-mode: overlay; 
background-position: center center;
background-repeat: no-repeat;
background-size: cover;
text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.section-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border-bottom: solid 1px #ddd; */
  margin-top: 20px;
  padding-bottom: 20px;
}

.section-container:first-child {
  margin-top: 0px;
}


.container-input {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
    column-gap: 25px;
    row-gap: 0px;
}


@media screen and (max-width: 768px) {
    .container-input {
      grid-template-columns: 1fr; /* 1 column for mobile */
    }
  }

  .request-container {
    /* background-color: #f4f4f4; */
    /* padding: 15px; */
}


  
  
  
  input {
    padding: 12px !important;
    border-radius:15px !important
    
  }
.submit-container{
  width: 100%;
  margin-top: 50px;
}

.submit-container button{
  width: 100%;
  border-radius: 15px !important;
  border: 2px solid #CBD2D8E5 !important;
}

.submit-container .submit-button{
  background-color: #055E9A !important; 
}


/* Base Styles */
.request-container {
    background-color: #FFFFFF;
    width: 100%;
    max-width: 1400px;
    min-height: calc(100vh - 56px);
    margin: 0 auto;
    padding: 40px;
    border-radius: 20px;
    border: 3px solid #CDD3D866;
    gap: 0;
}

.submit-container {
    width: 100%;
    margin-top: 50px;
}

.submit-container button {
    width: 100%;
    border-radius: 15px;
    border: 2px solid #CBD2D8E5;
    background-color: #055E9A;
    color: white;
    padding: 10px;
}

.container-input {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
    column-gap: 25px;
    row-gap: 20px;
}

.overlay-1, .overlay-2, .overlay-3 {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: background .3s, border-radius .3s, opacity .3s;
}

.overlay-1 {
    background-image: linear-gradient(
        rgba(91, 91, 91, 0.5),
        rgba(91, 91, 91, 0.5)
    ), url("../../assets/Frame\ 1.jpg");
}

.overlay-2, .overlay-3 {
    background-image: linear-gradient(
        rgba(91, 91, 91, 0.5),
        rgba(91, 91, 91, 0.5)
    ), url("../../wallpaper1.jpeg");
}

.title-text {
    font-size: 28px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    margin: 50px 40px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

/* Responsive Styles */

/* Tablets and smaller laptops */
@media (max-width: 992px) {
    .container-input {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }

    .request-container {
        padding: 30px;
    }

    .title-text {
        font-size: 24px;
        line-height: 50px;
    }

    .submit-container {
        margin-top: 40px;
    }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
    .container-input {
        grid-template-columns: 1fr; /* 1 column */
        row-gap: 20px;
    }

    .request-container {
        padding: 20px;
    }

    .submit-container {
        margin-top: 30px;
    }

    .title-text {
        font-size: 22px;
        line-height: 40px;
        margin: 30px 20px;
    }

    .overlay-1, .overlay-2, .overlay-3 {
        height: 200px;
    }
}

/* Small mobile (max-width: 576px) */
@media (max-width: 576px) {
    .request-container {
        padding: 15px;
    }

    .submit-container {
        margin-top: 20px;
    }

    .title-text {
        font-size: 20px;
        line-height: 30px;
        margin: 20px 10px;
    }

    .overlay-1, .overlay-2, .overlay-3 {
        height: 150px;
    }

    .submit-container button {
        padding: 8px;
    }
}
